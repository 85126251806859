<template>
    <v-footer id="default-footer" color="transparent" absolute app inset>
        <cookie-law
            theme="base"
            :message="$t('Core.sentence.cookies_consent')"
            :buttonLinkText="$t('Core.btn.more_info')"
            :buttonText="$t('Core.btn.authorize')"
            :buttonLinkNewTab="true"
            buttonLink="/policy/cookie.html"
        >
        </cookie-law>

        <links>
            {{ version }}
        </links>
    </v-footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
export default {
    name: 'LoginFooter',
    components: { CookieLaw },
    data: () => ({
        version: FULL_VERSION_PHP,
    }),
}
</script>

<style scoped>
#default-footer {
    z-index: 11;
}
</style>
