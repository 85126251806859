<template>
    <v-btn class="ml-3 mr-4" elevation="1" fab small @click="mini = !mini">
        <v-icon>
            {{ mini ? 'mdi-format-list-bulleted' : 'mdi-dots-vertical' }}
        </v-icon>
    </v-btn>
</template>

<script lang="ts">
import Vue from 'vue'
import useLayoutStore from '@stores/layout'
import { mapWritableState } from 'pinia'

export default Vue.extend({
    name: 'DefaultDrawerToggle',

    computed: {
        ...mapWritableState(useLayoutStore, ['mini']),
    },
    mounted() {},
})
</script>
