<template>
    <v-footer id="default-footer" color="transparent" absolute app inset>
        <links />
    </v-footer>
</template>

<script>
// Components

export default {
    name: 'DefaultFooter',
}
</script>
