import Vue from 'vue'

import './vue-meta'
import './toast'
import './vue-flag'
import './vue-i18n-filter'
import './axios'

if (import.meta.env.PROD) {
    Vue.config.devtools = false
    Vue.config.performance = true
    Vue.config.silent = true
    Vue.config.productionTip = false
} else {
    Vue.config.productionTip = true
    Vue.config.devtools = true
}
