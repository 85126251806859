import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueI18nFilter from '@libs/vue-i18n-filter'
import type {
    DateTimeFormat,
    DateTimeFormatOptions,
    DateTimeFormats,
} from 'vue-i18n'
Vue.use(VueI18n)
Vue.use(VueI18nFilter)

import * as vuetifyLangs from 'vuetify/src/locale'

/**Load module data */
import { langs } from '../modules'

const availableLangs = window.LANGS_PHP.map((lang) =>
    lang.abbr.replaceAll('-', '_')
)

const module_langs: { [lang: string]: any } = {}
for (const lang of availableLangs) {
    module_langs[lang] = {}
}

for (const moduleName in langs) {
    let module: { [lang: string]: any } = {}
    for (const lang of availableLangs) {
        module[lang] = langs[moduleName].find(
            ({ name }: { name: string }) => name === lang
        )
        if (module[lang]) {
            module_langs[lang][moduleName] = module[lang].import
            //@ts-ignore
            module_langs[lang]['$vuetify'] = vuetifyLangs[lang]
        }
    }
}

/**
 * Exceções, no qual linguagens não possuem um arquivo próprio de locale.
 * Então vai ser usado alguma linguagem parecida ou até um arquivo customizado.
 */
module_langs['pt_BR']['$vuetify'] = vuetifyLangs['pt']

/**
 * Aqui a situação é um pouco complicada
 * 1-Para cada linguagem deve ser feita manualmente a adição do time format da região
 * 2-Só existem short e long?
 * 3-A linguagem n pode usar _ e deve usar o -
 * 4-depende da biblioteca formatjs e de melhorias na mesma
 * 5-se estiver na base da normalidade, todos as linguagem terão os mesmos dados para printar, e a biblioteca vai deixar na formatação correta (10/28/2001, 05.12.2001,etc...)
 */
const dateTimeAll: DateTimeFormat = {
    date: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    },
    short: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    },
    month: {
        year: 'numeric',
        month: 'long',
    },
    long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
    },
}
const dateTimeFormats: DateTimeFormats = {
    en: dateTimeAll,
    'pt-BR': dateTimeAll,
    es: dateTimeAll,
}

const numberFormats = {
    en: {
        currency: {
            style: 'currency',
            currency: 'USD',
        },
    },
    'pt-BR': {
        currency: {
            style: 'currency',
            currency: 'BRL',
        },
    },
    es: {
        currency: {
            style: 'currency',
            currency: 'EUR',
        },
    },
}

const i18n = new VueI18n({
    fallbackLocale: 'pt_BR',
    dateTimeFormats,
    numberFormats,
    messages: module_langs,
})

export default i18n
