type Theme = {
    primary: string
    secondary: string
    accent: string
    error: string
    info: string
    success: string
    warning: string
    primary_lighten1?: string
    primary_lighten2?: string
    primary_lighten3?: string
    primary_lighten4?: string
    primary_lighten5?: string
    secondary_lighten1?: string
    secondary_lighten2?: string
    secondary_lighten3?: string
    secondary_lighten4?: string
    secondary_lighten5?: string
    primary_darken1?: string
    primary_darken2?: string
    primary_darken3?: string
    primary_darken4?: string
    primary_darken5?: string
    secondary_darken1?: string
    secondary_darken2?: string
    secondary_darken3?: string
    secondary_darken4?: string
    secondary_darken5?: string
}

const defaultTheme: { light: Theme; dark: Theme } = {
    light: {
        primary: '#4392F1',
        secondary: '#e0e0e0',
        accent: '#253C78',
        error: '#f44336',
        warning: '#ff9800',
        info: '#00bcd4',
        success: '#8bc34a',
    },
    dark: {
        primary: '#A0A',
        secondary: '#A0A',
        accent: '#A0A',
        error: '#A0A',
        info: '#A0A',
        success: '#A0A',
        warning: '#A0A',
    },
}

function increase_brightness(hex: string, percent: number) {
    // strip the leading # if it's there
    hex = hex.replace(/^\s*#|\s*$/g, '')

    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if (hex.length == 3) {
        hex = hex.replace(/(.)/g, '$1$1')
    }

    var r = parseInt(hex.substring(0, 2), 16),
        g = parseInt(hex.substring(2, 4), 16),
        b = parseInt(hex.substring(4, 6), 16)
    return (
        '#' +
        (0 | ((1 << 8) + r + ((256 - r) * percent) / 100))
            .toString(16)
            .substring(1) +
        (0 | ((1 << 8) + g + ((256 - g) * percent) / 100))
            .toString(16)
            .substring(1) +
        (0 | ((1 << 8) + b + ((256 - b) * percent) / 100))
            .toString(16)
            .substring(1)
    )
}
function decrease_brightness(hex: string, percent: number) {
    // strip the leading # if it's there
    hex = hex.replace(/^\s*#|\s*$/g, '')

    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if (hex.length == 3) {
        hex = hex.replace(/(.)/g, '$1$1')
    }

    var r = parseInt(hex.substring(0, 2), 16),
        g = parseInt(hex.substring(2, 4), 16),
        b = parseInt(hex.substring(4, 6), 16)
    return (
        '#' +
        (0 | ((1 << 8) + (r * (100 - percent)) / 100))
            .toString(16)
            .substring(1) +
        (0 | ((1 << 8) + (g * (100 - percent)) / 100))
            .toString(16)
            .substring(1) +
        (0 | ((1 << 8) + (b * (100 - percent)) / 100)).toString(16).substring(1)
    )
}

export function calculatePalette(palette: Theme, dark = false) {
    if (!palette) return palette

    if (palette.primary) {
        palette.primary_lighten1 = increase_brightness(palette.primary, 10)
        palette.primary_lighten2 = increase_brightness(palette.primary, 20)
        palette.primary_lighten3 = increase_brightness(palette.primary, 35)
        palette.primary_lighten4 = increase_brightness(palette.primary, 50)
        palette.primary_lighten5 = increase_brightness(palette.primary, 70)
    }
    if (palette.secondary) {
        palette.secondary_lighten1 = increase_brightness(palette.secondary, 10)
        palette.secondary_lighten2 = increase_brightness(palette.secondary, 20)
        palette.secondary_lighten3 = increase_brightness(palette.secondary, 35)
        palette.secondary_lighten4 = increase_brightness(palette.secondary, 50)
        palette.secondary_lighten5 = increase_brightness(palette.secondary, 70)
    }
    if (palette.primary) {
        palette.primary_darken1 = decrease_brightness(palette.primary, 10)
        palette.primary_darken2 = decrease_brightness(palette.primary, 25)
        palette.primary_darken3 = decrease_brightness(palette.primary, 40)
        palette.primary_darken4 = decrease_brightness(palette.primary, 60)
        palette.primary_darken5 = decrease_brightness(palette.primary, 80)
    }
    if (palette.secondary) {
        palette.secondary_darken1 = decrease_brightness(palette.secondary, 10)
        palette.secondary_darken2 = decrease_brightness(palette.secondary, 25)
        palette.secondary_darken3 = decrease_brightness(palette.secondary, 40)
        palette.secondary_darken4 = decrease_brightness(palette.secondary, 60)
        palette.secondary_darken5 = decrease_brightness(palette.secondary, 80)
    }
    return palette
}

defaultTheme.dark = calculatePalette(defaultTheme.dark, true)
defaultTheme.light = calculatePalette(defaultTheme.light, false)

export default defaultTheme
