import Vue from 'vue'
import { createPinia, PiniaVuePlugin, setActivePinia } from 'pinia'
import { createPersistedStatePlugin } from 'pinia-plugin-persistedstate-2'
import SecureLS from 'secure-ls'

//Criptografa a informação que é salva no frontend
const ls = new SecureLS({ encodingType: 'AES', isCompression: false })

const pinia = createPinia()
Vue.use(PiniaVuePlugin)
pinia.use(
    createPersistedStatePlugin({
        storage: import.meta.env.PROD
            ? {
                  getItem: (key: string) => String(ls.get(key)),
                  setItem: (key: string, value: string) => ls.set(key, value),
                  removeItem: (key: string) => ls.remove(key),
              }
            : localStorage,
        persist: false,
    })
)
setActivePinia(pinia)

export default pinia
