<template>
    <v-app-bar
        id="default-app-bar"
        app
        absolute
        class="v-bar--underline"
        color="transparent"
        :clipped-left="$vuetify.rtl"
        :clipped-right="!$vuetify.rtl"
        height="50"
        flat
    >
        <v-app-bar-nav-icon
            class="hidden-lg-and-up"
            @click="drawer = !drawer"
        />

        <!-- <default-drawer-toggle class="hidden-md-and-down" /> -->
        <transition-group
            name="bar"
            style="height: 50px; padding-left: 10px"
            class="v-toolbar__content"
        >
            <v-btn
                v-if="routerStack.length > 0"
                small
                icon
                key="1"
                @click="goBack"
            >
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-divider
                key="2"
                v-if="routerStack.length > 0"
                vertical
                class="mr-5 ml-1"
                inset
            ></v-divider>
            <v-breadcrumbs
                key="3"
                :items="items"
                divider=">"
                style="padding-left: 0; margin-top: 4px"
                id="breadcrumbs"
            >
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        v-bind="item"
                        v-if="item.disabled == false"
                    >
                        <v-toolbar-title class="text-subtitle-1">
                            {{ item.text | routeName | t }}
                        </v-toolbar-title>
                    </v-breadcrumbs-item>
                    <v-toolbar-title v-else class="text-subtitle-1">
                        {{ item.text | routeName | t }}
                    </v-toolbar-title>
                </template>
            </v-breadcrumbs>
        </transition-group>
        <v-spacer />

        <!-- <default-search class="hidden-md-and-down" /> -->

        <select-lang />
        <default-notifications />

        <default-account />
    </v-app-bar>
</template>

<script lang="ts">
// Utilities
import useLayoutStore from '@stores/layout'
import useAppStore from '@stores/app'
import Vue from 'vue'
import type { BreadCrumb } from '@src/types/BreadCrumb'
import DefaultAccount from './widgets/Account.vue'
import DefaultDrawerToggle from './widgets/DrawerToggle.vue'
import DefaultGoHome from './widgets/GoHome.vue'
import DefaultNotifications from './widgets/Notifications.vue'
import DefaultSearch from './widgets/Search.vue'
import { mapWritableState } from 'pinia'
import { mapState } from 'pinia'

export default Vue.extend({
    name: 'DefaultBar',
    components: {
        DefaultAccount,
        DefaultDrawerToggle,
        DefaultGoHome,
        DefaultNotifications,
        DefaultSearch,
    },
    methods: {
        goBack() {
            const route = this.routerStack.at(-1)
            if (!route) return

            this.$router.push(route.to)
        },
    },
    computed: {
        ...mapState(useAppStore, ['routerStack']),
        drawer: {
            get() {
                return useLayoutStore().drawer
            },
            set(value: boolean) {
                useLayoutStore().drawer = value
            },
        },
        items: {
            get(): Array<any> {
                const stack = this.routerStack.map((element) => {
                    return {
                        disabled: false,
                        to: element.to,
                        text: element.text,
                    }
                })
                return [
                    ...stack,
                    {
                        text: this.$route.name,
                        disabled: true,
                    },
                ]
            },
        },
    },
})
</script>

<style>
.bar-move, /* apply transition to moving elements */
.bar-enter-active,
.bar-leave-active {
    transition: all 0.8s ease-in-out;
}

#breadcrumbs.bar-move, /* apply transition to moving elements */
#breadcrumbs.bar-enter-active,
#breadcrumbs.bar-leave-active {
    transition: all 0.8s ease-out;
}

.bar-enter,
.bar-leave-to {
    opacity: 0;
    transform: translateY(-40px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.bar-leave-active {
    position: absolute;
}
</style>
