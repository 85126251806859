<template>
    <v-list-item class="mb-0 justify-space-between pl-3">
        <v-list-item-content class="pl-2">
            <v-list-item-title class="prog-title text-center">
                <strong
                    class="mr-1"
                    color="white"
                    style="font-size: 3em"
                    v-t="{ path: 'Core.app_name.v1' }"
                ></strong>
            </v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
// Utilities
export default {
    name: 'DefaultDrawerHeader',
}
</script>

<style>
.prog-title {
    font-family: 'Lobster', cursive;
}
</style>
