<template>
    <v-navigation-drawer
        id="default-drawer"
        v-model="drawer"
        :right="$vuetify.rtl"
        app
        width="280"
    >
        <default-drawer-header />

        <v-divider class="mx-3 mb-2" />

        <default-list class="v-list-group__items" :items="browseableRoutes" />

        <template #append>
            <div class="pa-4 text-center">{{ version }}</div>
        </template>

        <div class="pt-12" />
    </v-navigation-drawer>
</template>

<script>
// Utilities
import useLayoutStore from '@stores/layout'
import useAppStore from '@stores/app'
import useUserStore from '@stores/user'
import { mapActions, mapState, mapWritableState } from 'pinia'

export default {
    name: 'DefaultDrawer',

    components: {
        DefaultDrawerHeader: () => import('./widgets/DrawerHeader.vue'),
        DefaultList: () => import('./List.vue'),
    },
    computed: {
        ...mapState(useAppStore, ['version', 'loadingRoutes']),
        ...mapState(useUserStore, ['routeItems']),
        ...mapWritableState(useLayoutStore, ['drawer']),

        browseableRoutes() {
            const browseable = (e) => !!e.icon
            const checkItens = (routes) => {
                return routes
                    .filter(browseable)
                    .map((r) => {
                        let rot = r.routes
                        if (r.routes) rot = checkItens(r.routes)
                        return {
                            ...r,
                            routes: rot,
                        }
                    })
                    .filter((r) => !(r.routes && r.routes.length === 0))
            }

            return checkItens(this.routeItems)
        },
    },
    async mounted() {
        this.drawer = window.innerWidth >= 1264
    },
    created: () => {},
}
</script>

<style lang="sass">
#default-drawer
  .v-list-item
    margin-bottom: 3px

  .v-list-group__items
    padding-left: 0px
    padding-right: 0px
    .v-list-item
      border-left: 4px solid var(--v-primary_darken1-base) !important
      margin-left: -1px
      margin-right: -1px
      border-radius: 0px
      background-color: #fefefe
      min-height: 24px
    .v-list-item:hover:not(.v-list-item--active)
      background-color: var(--v-secondary_lighten2-base) !important
    .white--text
      color: rgba(0, 0, 0, 0.87) !important
      caret-color: rgba(0, 0, 0, 0.87) !important

  .v-list-item::before,
  .v-list-item::after
    display: none

  .v-list-item__title
    white-space: normal

  .v-list-group__header__prepend-icon,
  .v-list-item__icon
    margin-top: 2px !important
    margin-bottom: 2px !important
    margin-left: 4px
    margin-right: 12px

  .v-list-item__content
    padding: 2px 0

  .v-list-group__header
    padding: 4px 8px

  .v-list-group__items
    & > .v-list-group--active > .v-list-group__header
      background-color: var(--v-primary_lighten3-base) !important
      color: #FFFFFF !important
      caret-color: #FFFFFF !important
    & > .v-list-item--active
      background-color: var(--v-primary_lighten3-base) !important
      color: #FFFFFF !important
      caret-color: #FFFFFF !important

  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important
</style>
