<template>
    <v-app>
        <v-progress-linear
            :active="lazyLoadingRoute"
            :indeterminate="lazyLoadingRoute"
            absolute
            top
            color="primary"
        ></v-progress-linear>

        <default-bar />

        <default-drawer />

        <default-view />

        <default-footer />

        <cookie-law
            theme="base"
            :message="$t('Core.sentence.cookies_consent')"
            :buttonLinkText="$t('Core.btn.more_info')"
            :buttonText="$t('Core.btn.authorize')"
            :buttonLinkNewTab="true"
            buttonLink="/policy/cookie.html"
            style="z-index: 10"
        >
        </cookie-law>
        <!-- <default-settings /> -->
    </v-app>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import CookieLaw from 'vue-cookie-law'
import useLayoutStore from '@stores/layout'
import useUserStore from '@stores/user'
import useAppStore from '@stores/app'
import { mapWritableState } from 'pinia'

export default {
    name: 'DefaultLayout',

    components: {
        DefaultBar: () => import('./AppBar.vue'),
        DefaultDrawer: () => import('./Drawer.vue'),
        DefaultFooter: () => import('./Footer.vue'),
        DefaultSettings: () => import('./Settings.vue'),
        DefaultView: () => import('./View.vue'),
        CookieLaw,
    },
    data() {
        return {
            lastRoute: null,
        }
    },
    computed: {
        ...mapWritableState(useAppStore, ['routerStack']),
        ...mapState(useAppStore, ['lazyLoadingRoute']),
    },
    methods: {
        ...mapActions(useLayoutStore, ['updateTheme']),
        ...mapActions(useUserStore, ['updateModules', 'updateRoutes']),
        updateBreadCrumbs(to, from) {
            if (to.name == from.name) return
            if (to.params.root) {
                this.routerStack.splice(0, this.routerStack.length)
            } else {
                const routeExistent = this.routerStack.find(
                    (route) => route.text == to.name
                )
                if (routeExistent) {
                    this.routerStack = this.routerStack.slice(
                        0,
                        this.routerStack.indexOf(routeExistent)
                    )
                } else {
                    this.routerStack.push({
                        text: from.name ?? '',
                        disabled: false,
                        to: {
                            name: from.name ?? '',
                            query: from.query,
                            params: from.params,
                        },
                    })
                }
            }
        },
    },
    created() {
        const self = this
        this.updateModules()
        this.updateTheme()
        this.updateRoutes()
    },
    beforeRouteEnter(to, from, next) {
        console.log('beforeenter')
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        next()
    },
    beforeRouteUpdate(to, from, next) {
        this.updateBreadCrumbs(to, from)
        next()
    },
    beforeRouteLeave(to, from, next) {
        this.updateBreadCrumbs(to, from)
        next()
    },
}
</script>
