import Vue from 'vue'
import Vuetify from 'vuetify'

import i18n from './i18n'

Vue.use(Vuetify)

import theme from '../helper/theme'

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    lang: {
        t: (key, ...params) => i18n.t(key, params).toString(),
    },
    theme: {
        themes: theme,
        dark: false,
        options: { customProperties: true },
    },
})
