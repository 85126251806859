import type { VueConstructor, ComponentOptions } from 'vue'
import type IVue from 'vue'
import type { IVueI18n } from 'vue-i18n'

export default {
    install(Vue: VueConstructor) {
        Vue.mixin({
            beforeCreate(): void {
                const { filters } = this.$options as ComponentOptions<IVue>
                if (!filters) return

                if (typeof filters.t === 'undefined') {
                    filters.t = (...arg: any) =>
                        (this.$i18n as IVueI18n)
                            ? this['$t']?.apply(this, arg)
                            : arg[0]
                }
                if (typeof filters.tc === 'undefined') {
                    filters.tc = (...arg: any) =>
                        (this.$i18n as IVueI18n)
                            ? this['$tc']?.apply(this, arg)
                            : arg[0]
                }
                if (typeof filters.te === 'undefined') {
                    filters.te = (...arg: any) =>
                        (this.$i18n as IVueI18n)
                            ? this['$te']?.apply(this, arg)
                            : arg[0]
                }
            },
            beforeDestroy(): void {
                // const { filters } = this.$options as ComponentOptions<IVue>
                // delete filters?.t
                // delete filters?.te
                // delete filters?.tc
            },
        })
    },
}
