import Vue from 'vue'

/** Import css in javascript
 * as encouraged in laravel and vite docs
 */
import '/resources/sass/app.scss'

/**Setup all libraries */
import '@libs/setup'

/**Load plugins */
import pinia from '@plugins/pinia'
import i18n from '@plugins/i18n'
import vuetify from '@plugins/vuetify'
import router from '@plugins/router'

/**Load filters*/
import './helper/filters'

/**Load Mixin */
import './helper/mixin'

/**Load vue components for each module */
import './components'

const cntx = {
    pinia,
    router,
    vuetify,
    i18n,
}

// Run setup function for every module
import { setups } from './modules'

for (const moduleName in setups) {
    for (const setup in setups[moduleName]) {
        setups[moduleName][setup].import(cntx)
    }
}

// Create vue instance, and attach it to the DOM
import App from '@src/vue/App.vue'

new Vue({
    ...cntx,
    render: (r) => r(App),
}).$mount('#app')
