import Vue from 'vue'
import VueRouter from 'vue-router'
import type { RouteConfig } from 'vue-router'
import { layout, error, redirect, moduleRoute } from '../helper/routes'
import Toast from '@libs/toast'
import useUserStore from '@stores/user'
import pinia from '@plugins/pinia'

Vue.use(VueRouter)
const userStore = useUserStore(pinia)
function createRouter() {
    return new VueRouter({
        mode: 'history',
        scrollBehavior: (to, from, savedPosition) => {
            if (to.hash)
                return {
                    selector: to.hash,
                }
            if (savedPosition) return savedPosition

            return {
                x: 0,
                y: 0,
            }
        },
        routes: [],
    })
}
const router = createRouter()

// FIXME vue router 4 adiciona função removeRoute
export function resetRouter() {
    // @ts-ignore
    router.matcher = createRouter().matcher
}

function getTypeUser() {
    let type
    if (userStore.isLoggedIn) {
        if (userStore.isAdmin) {
            type = 'admin'
        } else {
            type = 'user'
        }
    } else {
        type = 'guest'
    }
    return type
}

function getRouterForArray(items: Route[]): RouteConfig[] {
    let routes: RouteConfig[] = []

    items.forEach(function (element) {
        if (element.routes) {
            routes = routes.concat(getRouterForArray(element.routes))
        } else if (element.location) {
            let module
            if (element.location.includes('@')) {
                let aux = element.location.split('@')
                module = moduleRoute(aux[0], aux[1], element.path)
            } else {
                module = moduleRoute('Core', element.location, element.path)
            }
            if (module) routes.push(module)
        }
    })
    return routes
}

async function updateRouter() {
    let type = getTypeUser()
    resetRouter()
    let itensNavigation: Route[] = []
    let routes: RouteConfig[] = [],
        route: RouteConfig | null

    switch (type) {
        case 'guest':
            route = moduleRoute('Core', 'AdminLogin', '/login/admin')
            route && routes.push(route)

            route = moduleRoute('Core', 'UserLogin', '/login/user/:redirectTo?')
            route && routes.push(route)

            route = moduleRoute('Core', 'Register', '/register/:redirectTo?')
            route && routes.push(route)

            route = redirect('*', () => '/login/user')
            route && routes.push(route)

            router.addRoute(layout('Login', routes, '/login'))
            router.addRoute(
                redirect(
                    '*',
                    () => {
                        return '/login/user'
                    },
                    'Redirect User'
                )
            )
            router.addRoute(error(404, '*'))
            break
        case 'user':
        case 'admin':
            itensNavigation = userStore.routeItems
            if (itensNavigation.length === 0) {
                // quando esta vazio de itens ele pega um default
                itensNavigation = [
                    {
                        name: 'NoAccess',
                        alias: 'NA',
                        title: 'Core.routes.NoAccess',
                        location: 'Core@NoAccess',
                        icon: 'mdi-alert-circle',
                        path: '/',
                    },
                ]
            }
            routes = getRouterForArray(itensNavigation)

            router.addRoute(layout('Default', routes, '/'))
            router.addRoute(
                redirect('*', () => {
                    return '/'
                })
            )
            router.addRoute(error(404, '*'))
            userStore.routeItems = itensNavigation
            break
    }

    route = moduleRoute(
        'Core',
        'EmployeeInvitation',
        '/core/web/employeeInvitation/:token'
    )
    route && router.addRoute(layout('Login', [route], '/core'))
}

updateRouter()

/** Interceptor error 403 and 401 axios */
import axios from '@libs/axios'
import type { Route } from '../models/Route'

let isRefreshing = false

const backLogin = (err: any) => {
    updateRouter()
    if (userStore.isAdmin) {
        router.push('/login/admin/')
        return Promise.reject(err)
    } else {
        router.push('/login/user/')
        return Promise.reject(err)
    }
}

axios.interceptors.response.use(
    (response) => {
        return response
    },
    async (err: any) => {
        const {
            config,
            response: { status },
        } = err

        const originalRequest = config
        if (originalRequest.url.includes('/auth/refresh')) {
            if (window.location.href.includes('/login/')) {
                return Promise.reject(err)
            } else {
                backLogin(err)
            }
        }

        if (originalRequest.url.includes('/auth/login')) {
            return Promise.reject(err)
        }

        if (status === 403) {
            return Promise.reject(err)
        } else if (status === 401 && userStore.isLoggedIn) {
            if (!isRefreshing) {
                isRefreshing = true
                await userStore
                    .refreshToken()
                    .then((response) => {
                        if (
                            response.status === 200 ||
                            response.status === 204
                        ) {
                            isRefreshing = false
                            if (import.meta.env.DEV)
                                Toast({
                                    icon: 'info',
                                    title: 'Token foi renovado',
                                })
                        }
                    })
                    .catch((error) => {
                        userStore.isLoggedIn = false
                        userStore.isAdmin = false
                        if (import.meta.env.DEV)
                            Toast({
                                icon: 'warning',
                                title: 'Falha na tentativa de renovar o token',
                            })
                        backLogin(error)
                    })
                if (!isRefreshing) {
                    return axios(originalRequest)
                }
            } else {
                if (
                    originalRequest.url.includes('/auth/refresh') ||
                    originalRequest.url.includes('logout')
                ) {
                    return Promise.reject(err)
                } else
                    return new Promise((resolve) =>
                        setTimeout(resolve, 5000)
                    ).then(() => axios(originalRequest))
            }
        } else {
            return Promise.reject(err)
        }
    }
)

window.router = router
window.updateRouter = updateRouter

export default router
