<template>
    <v-main id="main">
        <v-container fluid>
            <v-row justify="end" id="header">
                <v-flex shrink>
                    <select-lang></select-lang>
                </v-flex>
            </v-row>
            <router-view :key="$route.path" />
        </v-container>
    </v-main>
</template>

<script>
export default {
    name: 'LoginView',
}
</script>

<style scoped>
#main {
    z-index: 10;
}
#header {
    margin-bottom: 20px;
}
</style>
