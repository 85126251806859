import { mapActions } from 'pinia'
import Vue from 'vue'
import useWebStore from '../stores/web'

Vue.mixin({
    methods: {
        ...mapActions(useWebStore, [
            'get',
            'put',
            'post',
            'postFile',
            'delete',
        ]),
        prepareDataToSend(data: object, inputs: any[], originalData: object) {
            if (!data || !inputs || !originalData) return {}
            let updatedItem: any = {}
            let getItens = function (
                inputs: any[],
                itemActual: any,
                originalItem: any
            ) {
                inputs.forEach((input) => {
                    if (input.ignore) return

                    const originalVal = originalItem[input.name]
                    if (
                        input.inputs &&
                        input.subItem &&
                        itemActual[input.subItem]
                    ) {
                        if (originalItem[input.subItem]) {
                            getItens(
                                input.inputs,
                                itemActual[input.subItem],
                                originalItem[input.subItem]
                            )
                        } else {
                            getItens(
                                input.inputs,
                                itemActual[input.subItem],
                                {}
                            )
                        }
                    } else if (input.inputs) {
                        getItens(input.inputs, itemActual, originalItem)
                    } else if (Array.isArray(originalVal)) {
                        const sortedItemActual = itemActual[input.name].sort()
                        if (
                            originalVal.length !=
                                itemActual[input.name].length ||
                            (!!input.input?.select &&
                                originalVal
                                    .map((i) => i[input.input.select])
                                    .sort()
                                    .some(
                                        (i, idx) => i != sortedItemActual[idx]
                                    ))
                        ) {
                            const sendName = input.onSendName
                                ? input.onSendName
                                : input.name
                            if (input.filter)
                                updatedItem[sendName] =
                                    input.filter(sortedItemActual)
                            else updatedItem[sendName] = sortedItemActual
                        }
                    } else {
                        if (
                            originalVal != itemActual[input.name] &&
                            String(itemActual[input.name]).length > 0
                        ) {
                            const sendName = input.onSendName
                                ? input.onSendName
                                : input.name
                            if (input.filter)
                                updatedItem[sendName] = input.filter(
                                    itemActual[input.name]
                                )
                            else updatedItem[sendName] = itemActual[input.name]
                        }
                    }
                })
            }
            getItens(inputs, data, originalData)
            return updatedItem
        },
        globalUpdateObject(object: object, path: string, value: any) {
            const search = function (path: string, obj: any, value: any) {
                const arr = path.split(/\.(.*)/s)
                if (arr.length >= 2 && arr[1].length > 0) {
                    if (!obj[arr[0]] || typeof obj[arr[0]] != 'object')
                        obj[arr[0]] = {}
                    obj[arr[0]] = search(arr[1], obj[arr[0]], value)
                } else {
                    obj[arr[0]] = value
                }
                return obj[arr[0]]
            }
            search(path, object, value)
            return object
        },
    },
})
