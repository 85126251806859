<template>
    <v-main style="padding-bottom: 70px">
        <v-container fluid>
            <keep-alive :include="/persistent/i" :max="8">
                <router-view :key="$route.path" />
            </keep-alive>
        </v-container>
    </v-main>
</template>

<script>
export default {
    name: 'DefaultView',
}
</script>
