<template>
    <v-menu
        bottom
        left
        min-width="200"
        offset-y
        origin="top right"
        transition="scale-transition"
    >
        <template v-slot:activator="{ attrs, on }">
            <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
                <v-icon>mdi-account</v-icon>
            </v-btn>
        </template>

        <v-list :tile="false" flat nav>
            <template v-for="(p, i) in profile">
                <v-divider
                    v-if="p.divider"
                    :key="`divider-${i}`"
                    class="mb-2 mt-2"
                />

                <app-bar-item v-else :key="`item-${i}`">
                    <div
                        @click="p.action()"
                        style="min-height: 48px; padding-top: 10%; width: 100%"
                    >
                        <v-list-item-title v-text="p.title" />
                    </div>
                </app-bar-item>
            </template>
        </v-list>
    </v-menu>
</template>

<script lang="ts">
import Vue from 'vue'
import useUserStore from '@stores/user'
import useAppStore from '@stores/app'
import { mapState, mapActions } from 'pinia'

export default Vue.extend({
    name: 'DefaultAccount',
    computed: {
        ...mapState(useUserStore, ['isAdmin']),
        ...mapState(useAppStore, ['lazyLoadingRoute']),
    },
    methods: {
        ...mapActions(useUserStore, ['logout']),
    },
    data() {
        return {
            profile: [
                {
                    title: 'Perfil',
                    action: () => this.$router.push('/config/profile'),
                },
                {
                    divider: true,
                    action: () => null,
                },
                {
                    title: 'Sair',
                    action: () => this.logout(),
                },
            ],
        }
    },
})
</script>
