<template>
    <v-list
        expand
        nav
        v-bind="$attrs"
        v-on="$listeners"
        style="position: relative"
    >
        <template v-for="(item, i) in items">
            <default-list-group
                v-if="item.routes"
                :key="`group-${i}`"
                :item="item"
            />

            <default-list-item v-else :key="`item-${i}`" :item="item" />
        </template>
        <v-overlay :absolute="true" :value="loading" style="margin: 3px">
            <v-progress-circular
                indeterminate
                size="48"
                width="3"
                color="primary"
            ></v-progress-circular>
        </v-overlay>
    </v-list>
</template>

<script>
export default {
    name: 'DefaultList',

    components: {
        DefaultListGroup: () => import('./ListGroup.vue'),
        DefaultListItem: () => import('./ListItem.vue'),
    },

    props: {
        items: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
