<template>
    <v-list-item
        :href="item.href"
        :rel="item.href ? 'nofollow' : undefined"
        :target="item.href ? '_blank' : undefined"
        :to="{ name: location, params: { root: true } }"
        active-class="info white--text"
        link
        exact
        class="py-1"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <v-list-item-avatar v-if="item.avatar">
            <v-img :src="item.avatar" />
        </v-list-item-avatar>

        <v-list-item-icon v-if="item.icon" class="my-2 align-self-center">
            <v-icon :class="textColor" v-text="item.icon" />
        </v-list-item-icon>

        <v-list-item-content v-if="item.title">
            <v-list-item-title
                style="font-size: 13px"
                :class="textColor"
                v-text="$t(item.title)"
            />
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: 'DefaultListItem',

    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
        textColor: String,
    },

    computed: {
        title() {
            const matches = this.item.title.match(/\b(\w)/g)

            return matches.join('')
        },
        location() {
            if (this.item.location.includes('@')) {
                return this.item.location
            } else {
                return 'Core@' + this.item.location
            }
        },
    },
}
</script>
