import toastr from 'toastr'

type Args = { icon: string; title: string }
export default function ({ icon, title }: Args) {
    toastr.options = {
        closeButton: true,
        debug: false,
        newestOnTop: false,
        progressBar: true,
        positionClass: 'toast-top-right',
        preventDuplicates: true,
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 4000,
        extendedTimeOut: 3000,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
    }
    switch (icon.toLowerCase()) {
        case 'error':
            toastr.options.timeOut = 6000
            toastr.options.extendedTimeOut = 2000
            toastr.error(title)
            break
        case 'warning':
            toastr.options.timeOut = 4000
            toastr.options.extendedTimeOut = 1000
            toastr.warning(title)
            break
        case 'success':
            toastr.options.timeOut = 3000
            toastr.options.extendedTimeOut = 500
            toastr.success(title)
            break
        case 'info':
        default:
            toastr.options.timeOut = 8000
            toastr.options.extendedTimeOut = 2000
            toastr.info(title)
            break
    }
}
