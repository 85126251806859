import Vue from 'vue'

/**Load module data */
import { components } from './modules'

for (const module in components) {
    const moduleComponents = components[module]
    /**Register Components */
    moduleComponents.forEach((component: any) => {
        const name =
            module === 'Core'
                ? component.name
                : component.name.includes(module)
                ? component.name
                : `${module}-${component.name}`

        Vue.component(name, component.import)
    })
}
