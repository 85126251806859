<template>
    <v-list-group eager v-bind="$attrs">
        <template v-slot:prependIcon>
            <v-img v-if="item.avatar" :src="item.avatar" />
            <v-icon v-if="item.icon" v-text="item.icon" />
        </template>
        <template v-slot:activator>
            <v-list-item-icon
                v-if="!item.icon && !item.avatar"
                class="text-caption text-uppercase text-center my-2 align-self-center"
                style="margin-top: 14px"
            >
                {{ title }}
            </v-list-item-icon>

            <v-list-item-content v-if="item.title">
                <v-list-item-title
                    style="font-size: 13px"
                    v-text="$t(item.title)"
                />
            </v-list-item-content>
        </template>
        <template v-for="(child, i) in item.routes">
            <default-list-group
                v-if="child.routes"
                class="float-right"
                style="width: 96%"
                :key="`sub-group-${i}`"
                :item="child"
            />

            <default-list-item
                v-else
                class="float-right"
                style="width: 96%"
                :key="`child-${i}`"
                :item="child"
            />
        </template>
    </v-list-group>
</template>

<script>
// Utilities

export default {
    name: 'DefaultListGroup',

    components: {
        DefaultListItem: () => import('./ListItem.vue'),
    },

    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
        bgColor: String,
    },

    computed: {
        group() {
            return this.genGroup(this.item.items)
        },
        title() {
            const matches = this.item.title.match(/\b(\w)/g)

            return matches.join('')
        },
    },

    methods: {
        genGroup(items) {
            return items
                .reduce((acc, cur) => {
                    if (!cur.to) return acc

                    acc.push(
                        cur.items
                            ? this.genGroup(cur.items)
                            : cur.to.slice(1, -1)
                    )

                    return acc
                }, [])
                .join('|')
        },
    },
}
</script>
<style>
.v-list-item__icon {
    align-self: center !important;
}
</style>
