<template>
    <div>
        <v-fade-transition mode="out-in">
            <router-view />
        </v-fade-transition>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
import i18n from '@plugins/i18n'
import useUserStore from '@stores/user'
import useLayoutStore from '@stores/layout'
import { mapActions, mapState } from 'pinia'

export default Vue.extend({
    name: 'App',
    computed: {
        ...mapState(useUserStore, ['locale']),
        ...mapState(useLayoutStore, ['theme']),
    },
    watch: {
        theme(value) {
            this.setTheme(value)
        },
        dark(value) {
            this.$vuetify.theme.dark = value
        },
    },
    methods: {
        ...mapActions(useUserStore, ['changeLocale']),
        setTheme(theme: any) {
            const dark = theme.dark
            const light = theme.light
            this.$nextTick(() => {
                if (dark)
                    Object.keys(dark).forEach((i) => {
                        this.$vuetify.theme.themes.dark[i] = dark[i]
                    })
                if (light)
                    Object.keys(light).forEach((i) => {
                        this.$vuetify.theme.themes.light[i] = light[i]
                    })
            })
        },
    },
    mounted() {
        this.setTheme(this.theme)
        this.$i18n.locale = this.locale
        this.changeLocale(this.locale)
    },
    metaInfo: {
        title: 'PCare',
        titleTemplate: '%s | ' + process.env.APP_NAME,
        htmlAttrs: { lang: i18n.locale },
        meta: [
            { charset: 'utf-8' },
            {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1',
            },
        ],
    },
})
</script>
