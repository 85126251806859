<template>
    <v-app>
        <login-view />
        <login-footer />
    </v-app>
</template>

<script>
export default {
    name: 'LoginLayout',

    components: {
        LoginFooter: () => import('./Footer.vue'),
        LoginView: () => import('./View.vue'),
    },
}
</script>
