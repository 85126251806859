<template>
    <v-menu
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
    >
        <template v-slot:activator="{ attrs, on }">
            <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
                <v-icon v-if="notifications.length == 0">mdi-bell</v-icon>
                <v-badge v-else bordered color="red" overlap>
                    <template v-slot:badge>
                        {{ notifications.length }}
                    </template>
                    <v-icon>mdi-bell</v-icon>
                </v-badge>
            </v-btn>
        </template>

        <v-list flat nav>
            <app-bar-item v-for="(n, i) in notifications" :key="i" link>
                <v-list-item-content>
                    <v-list-item-title>{{ n }} </v-list-item-title>
                </v-list-item-content>
            </app-bar-item>

            <v-list-item-content item-content v-if="notifications.length === 0">
                <v-list-item-title>Sem Notificações</v-list-item-title>
            </v-list-item-content>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'DefaultNotifications',

    data: () => ({
        notifications: [],
    }),
    methods: {},
}
</script>
